import React, { createContext, useReducer, useContext } from 'react'
// https://kentcdodds.com/blog/how-to-use-react-context-effectively

const ActivityContext = createContext()

const initialState = {
  current: {}
}

const activityReducer = (state, action) => {
  switch (action.type) {
    case 'SET_SELECTED_ACTIVITY': {
      return {
        ...action.payload
      }
    }
    case 'UNSET_SELECTED_ACTIVITY': {
      return {
        ...initialState
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

export const ActivityProvider = ({ current, children }) => {
  const [ state, dispatch ] = useReducer(activityReducer, {
    ...initialState,
    current: { ...current }
  })

  const value = { state, dispatch }

  return (
    <ActivityContext.Provider value={value}>
      { children }
    </ActivityContext.Provider>
  )
}

export const useActivity = () => {
  const context = useContext(ActivityContext)

  if (context === undefined) {
    throw new Error(`useActivity must be used within an ActivityProvider`)
  }

  return context
}
