import React, { useEffect } from 'react'
import { useActivity } from '../context/activity-context'
import { Marker } from 'react-map-gl';

const ActivityMarkers = (props) => {
  const { state, dispatch } = useActivity()
  const { activities, moveToViewport } = props

  useEffect(() => {
    const current = state && state.current;

    if (current && current.id) {
      moveToViewport({
        longitude: current.location.lng,
        latitude: current.location.lat
      })
    }
  }, [state, activities, moveToViewport])

  return (
    <>
      {activities.map(activity => {
        const { current } = state
        const isActive = activity.id === current.id

        return (
          <Marker
            key={activity.id}
            id={activity.id}
            longitude={activity.location?.lng}
            latitude={activity.location?.lat}
            onClick={(event) => {
              window.history.pushState({}, '', `/kart/${activity.slug.current}`)
              dispatch({
                type: 'SET_SELECTED_ACTIVITY',
                payload: { current: { ...activity } }
              })
            }}
            style={{
              zIndex: `${isActive ? '20' : '0'}`
            }}
          >
            <div className={`map-marker-wrapper transition-transform duration-300 ${isActive ? 'scale-125': ''}`}>
              <div className={`map-marker transition-all duration-300 ${isActive ? 'bg-selected-pin' : ''}`}>
                <div className={`map-marker-text`}>
                  {activity.document_id}
                </div>
              </div>
              <div className={`map-marker-title`}>
                {activity.title}
              </div>
            </div>
          </Marker>
        )
      })}
    </>
  )
}

export default ActivityMarkers
