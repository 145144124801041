import React from 'react';

import ZoomIn from '../images/zoom-in.inline.svg';

export default function Drawer({
  header,
  children,
  isOpen,
  setIsOpen
}) {
  return (
    <main
      className={
        "fixed overflow-hidden z-30 inset-0 transform ease-in-out " +
        (isOpen
          ? " duration-500 translate-x-0  "
          : " transition-all delay-500 translate-x-full  ")
      }
    >
      <section
        className={
          "w-full md:max-w-[530px] right-0 absolute h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform  " +
          (isOpen ? " translate-x-0 " : " translate-x-full ")
        }
      >
        <article className="bg-blue-light relative flex flex-row flex-wrap w-full overflow-y-scroll h-full">
          <header className="flex items-center w-full justify-between p-[16px] md:p-[24px] pb-[0.75rem] md:pb-[0.75rem] font-bold text-h3 md:text-h3-md">
            <h3 className={`text-h4 md:text-h4-md mb-0`}>
              {header}
            </h3>
            <button className={`flex justify-center items-center bg-black text-white p-[5px] w-[44px] h-[44px] rounded-full`} onClick={() => {
              setIsOpen(false);
            }}>
              <ZoomIn className={`rotate-45`} />
            </button>
          </header>
          <div className={`px-[16px] md:px-[24px] w-full`}>
            {children}
          </div>
        </article>
      </section>
      <section
        className=" w-screen h-full cursor-pointer "
        onClick={() => {
          setIsOpen(false);
        }}
      ></section>
    </main>
  );
}
