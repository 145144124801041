import React from 'react'
import { useActivity } from '../context/activity-context'
import { buildImageObject, isEmptyObject, mapEdgesToNodes, blockContentToText } from "../utilities/helpers"
import { imageUrlFor } from '../utilities/image-url'

import PreviousArrow from '../images/previous.inline.svg';
import NextArrow from '../images/next.inline.svg';
import GotoArrow from '../images/goto.inline.svg';
import Close from '../images/close.inline.svg';

import Marker from '../../static/images/card-marker.inline.svg'

import { Link } from 'gatsby';
import ClampLines from 'react-clamp-lines';

const getCurrentActivityIndex = (current, activities) => {
  return activities.findIndex(activity => activity.id === current.id)
}

const getNextActivity = (current, activities) => {
  return activities[
    (getCurrentActivityIndex(current, activities) + activities.length + 1) % activities.length
  ];
}

const getPreviousActivity = (current, activities) => {
  return activities[
    (getCurrentActivityIndex(current, activities) + activities.length - 1) % activities.length
  ];
}

const ActivityPreview = props => {
  const {
    state: { current },
    dispatch
  } = useActivity()

  const {
    data,
    resetViewport
  } = props

  const activities = data && data.activities && mapEdgesToNodes(data.activities)

  /*
  * "Hack" to prevent background color classes from being purged.
  * <Hackerman className={`bg-pink bg-pink-dark bg-orange bg-orange-dark bg-blue bg-blue-dark`} />
  */

  return !isEmptyObject(current) && (
    <div className={`absolute bottom-[15px] md:bottom-[30px] left-0 right-0 m-[15px] md:m-0 z-20`}>
      <div className={`flex flex-wrap justify-end md:justify-center items-center`}>
        <button
          className={`order-1 mr-[10px] flex justify-center items-center bg-white border-black text-black p-[5px] w-[44px] h-[44px] rounded-full border-2`}
          onClick={() => {
            const prev_activity = getPreviousActivity(current, activities)

            window.history.pushState({}, '', `/kart/${prev_activity.slug.current}`)
            dispatch({
              type: "SET_SELECTED_ACTIVITY",
              payload: {
                current: {
                  ...prev_activity
                }
              }
            })}
          }
        >
          <PreviousArrow />
        </button>
        <div className={`${current.category.colors.title} border-2 border-black transition-colors order-3 md:order-2 mt-[15px] md:mt-0 p-[16px] md:p-[30px] rounded-[15px] w-full max-w-xl`}>
          <div className={`hidden md:block w-[120px] float-right`}>
            {current.image && current.image.asset && (
              <div className={`activity-image activity-image--square w-full`}>
                <figure>
                  <img
                    className={`max-h-[100px] max-w-[100px] w-full h-full`}
                    src={imageUrlFor(buildImageObject(current.image)).url()}
                    alt={current.image.alt}
                  />
                </figure>
              </div>
            )}
          </div>
          <div className={`flex mb-[16px] items-center`}>
            <div className={`mt-[3px] mr-[12px] bg-black text-white rounded-full flex items-center justify-center w-[2em] h-[2em] text-base md:text-base-md font-bold`}>
              {current.document_id}
            </div>
            <div className={`flex-1`}>
              <h3 className={`text-h4 md:text-h4-md font-bold mb-[4px]`}>
                {current.title}
              </h3>
              {current.category && current.category.title && (
                <div className={`text-small flex items-center`}>
                  <Marker />
                  <span className={`ml-[4px]`}>
                    {current.category?.title}
                  </span>
                </div>
              )}
              </div>
          </div>
          <div className={`text-small md:text-small-md`}>
            <div className={``}>
              <ClampLines key={current.id} text={blockContentToText(current.description)} buttons={false} innerElement="p" />
            </div>
            <div className={`flex flex-wrap flex-row pt-[15px]`}>
              <Link
                className={`mr-[8px] inline-flex items-center p-[12px_16px] rounded-[8px] bg-black text-white`}
                to={`/aktiviteter/${current.slug.current}/`}
              >
                <span className={`pr-[10px]`}>Les mer</span>
                <GotoArrow />
              </Link>
              <button onClick={() => {
                window.history.pushState({}, '', `/kart/`)
                dispatch({
                  type: "UNSET_SELECTED_ACTIVITY"
                })
                resetViewport()
              }}
                className={`inline-flex items-center p-[12px_16px] border-2 border-black rounded-[8px] bg-transparent text-black`}
              >
                <span className={`pr-[10px]`}>Lukk</span>
                <Close />
              </button>
            </div>
          </div>
        </div>
        <button
          className={`order-2 md:order-3 ml-[10px] flex justify-center items-center bg-white border-black text-black p-[5px] w-[44px] h-[44px] rounded-full border-2`}
          onClick={() => {
            const next_activity = getNextActivity(current, activities)

            window.history.pushState({}, '', `/kart/${next_activity.slug.current}`)
            dispatch({
              type: "SET_SELECTED_ACTIVITY",
              payload: {
                current: {
                  ...next_activity
                }
              }
            }
          )}}
        >
          <NextArrow />
        </button>
      </div>
    </div>
  )
}

export default ActivityPreview
