import React, { useRef, useCallback, useState } from "react"
import { graphql, Link } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Drawer from '../../components/Drawer'
import Header from '../../components/Header'
import Container from '../../components/container'

import { ActivityProvider, useActivity } from "../../context/activity-context"

import ActivityPreview from "../../components/activity-preview"

import ZoomIn from '../../images/zoom-in.inline.svg';
import ZoomOut from '../../images/zoom-out.inline.svg';
import Marker from '../../../static/images/card-marker.inline.svg'
import Hamburger from '../../../static/images/hamburger.inline.svg'

import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

import { buildImageObject, mapEdgesToNodes } from "../../utilities/helpers"
import { imageUrlFor } from "../../utilities/image-url"

import Map, { NavigationControl, AttributionControl, GeolocateControl } from 'react-map-gl';
import ActivityMarkers from "../../components/activity-markers"

// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

mapboxgl.accessToken = 'pk.eyJ1IjoidmVyZGVucy1rdWxlc3RlLWRhZyIsImEiOiJja2IyN2RwengwODJxMzBxb2lyZzZhdmp2In0.fu_Br71_O8aS6BmEyYP4iQ'
const MAPBOX_ACCESS_TOKEN = mapboxgl.accessToken;

const Activities = (props) => {
  const { state, dispatch } = useActivity()
  const { activities, setIsOpen } = props;

  return (
    <div className={`flex flex-col`}>
      {activities.map(activity => {
        const { document_id, id, slug, title, category, image } = activity;
        const { current } = state;
        const isActive = id === current.id;

        return (
          <button
            className={`${isActive ? 'bg-blue bg-opacity-100' : ''} mb-[12px] px-[16px] md:px-[24px] py-[16px] flex w-full text-left gap-[10px] items-center hover:bg-blue hover:bg-opacity-50 hover:cursor-pointer rounded-md`}
            key={id}
            onClick={() => {
              window.history.pushState({}, '', `/kart/${slug.current}`)

              dispatch({
                type: "SET_SELECTED_ACTIVITY",
                payload: {
                  current: {
                    ...activity
                  }
                }
              })

              setIsOpen(false)
            }
          }>
            <div className={`flex items-center w-full`}>
              <div className={`mt-[3px] mr-[12px] bg-black text-white rounded-full flex items-center justify-center w-[2em] h-[2em] text-base font-bold`}>
                {document_id}
              </div>
              <div className={`flex-1`}>
                <h3 className={`text-h4 md:text-h4-md font-bold mb-[4px]`}>
                  {title}
                </h3>
                {category && category.title && (
                  <div className={`text-small flex items-center`}>
                    <Marker />
                    <span className={`ml-[4px]`}>
                      {category?.title}
                    </span>
                  </div>
                )}
              </div>
              {image && image.asset && (
                <div className={`h-[64px] w-[75px] flex items-center justify-center`}>
                  <div className={`activity-image w-full`}>
                    <figure>
                      <img
                        className={`max-h-[75px] max-w-[75px] w-full h-full`}
                        src={imageUrlFor(buildImageObject(image)).url()}
                        alt={image.alt} />
                    </figure>
                  </div>
                </div>
              )}
            </div>
          </button>
        )
      })}
    </div>
  )
}

/*
 * "Hack" to prevent classe from being purged.
 * <Hackerman className={`mapboxgl-canvas`} />
 */

const MapPage = props => {
  const mapRef = useRef();
  const geolocateControlRef = useRef();

  const { data } = props
  const activities = data && data.activities
  const activity = data && data.activity

  const [viewState] = useState({
    latitude: activity ? activity.location.lat : 59.905922,
    longitude: activity ? activity.location.lng : 10.737294,
    zoom: activity ? 18 : 16
  })

  const moveToViewport = useCallback(({longitude, latitude}) => {
    mapRef.current?.flyTo({
      center: [longitude, latitude],
      duration: 1000,
      zoom: 18
    });
  }, []);

  const zoomIn = useCallback(() => {
    console.log(mapRef);
    mapRef.current?.zoomIn({
      duration: 1000
    })
  }, [])

  const zoomOut = useCallback(() => {
    mapRef.current?.zoomOut({
      duration: 1000
    })
  }, [])

  const resetViewport = useCallback(() => {
    mapRef.current?.flyTo({
      center: [
        10.737294,
        59.905922
      ],
      duration: 1000,
      zoom: 16
    })
  }, [])

  const geolocateControlTrigger = useCallback(() => {
    geolocateControlRef.current?.trigger()
  }, [])

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Layout>
      <ActivityProvider current={activity}>
        <Seo title="Kart" />
        <Container className={`absolute w-full left-0 right-0 z-10`} stretch={true}>
          <Header navigation={false}>
            <div className={`relative pt-[30px] md:pt-[60px]`}>
              <div className={`text-small md:text-small-md absolute right-0 top-[100%] z-10 flex flex-col items-end`}>
                <button className={`mb-[8px] flex justify-center items-center bg-black text-white p-[5px_20px] h-[44px] rounded-full`} onClick={() => setIsOpen(true) }>
                  <span className={`pr-[8px]`}>Alle aktiviteter</span>
                  <Hamburger />
                </button>
                <button className={`mb-[8px] flex justify-center items-center bg-white border-black text-black p-[5px] w-[44px] h-[44px] rounded-full border-2`} onClick={zoomIn}>
                  <ZoomIn />
                </button>
                <button className={`flex justify-center items-center bg-white border-black text-black p-[5px] w-[44px] h-[44px] rounded-full border-2`} onClick={zoomOut}>
                  <ZoomOut />
                </button>
              </div>
              <div className={`absolute left-0 top-[100%] z-10 text-small md:text-small-md`}>
                <Link to="/" className={`flex justify-center items-center bg-black text-white p-[5px_20px] h-[44px] rounded-full`} onClick={() => setIsOpen(true) }>
                  <span>Lukk kart</span>
                </Link>
              </div>
            </div>
          </Header>
        </Container>
        <div className={`flex flex-row relative`}>
          <Drawer header={`Aktiviteter`} isOpen={isOpen} setIsOpen={setIsOpen}>
            <Activities activities={mapEdgesToNodes(activities)} setIsOpen={setIsOpen} />
          </Drawer>
          <div className={`flex-1 h-screen relative`}>
            <Map
              ref={mapRef}
              initialViewState={viewState}
              mapStyle="mapbox://styles/verdens-kuleste-dag/cl6m0buou002r14o7hfu3cyb9"
              mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
              attributionControl={false}
            >
              <ActivityMarkers
                activities={mapEdgesToNodes(activities)}
                moveToViewport={moveToViewport}
              />
              <NavigationControl showZoom={false} showCompass={false} />
              <AttributionControl customAttribution={`Verdens kuleste dag`} />
              <GeolocateControl style={{ display: 'none' }} ref={geolocateControlRef} />
            </Map>
            <ActivityPreview { ...props } resetViewport={resetViewport} />
            <div className={`absolute bottom-[30px] md:bottom-[60px] left-0 right-0 z-10`}>
              <div className={`flex justify-center items-center text-small md:text-small-md`}>
                <button
                    className={`flex items-center p-[12px_16px] rounded-[8px] bg-black text-white`}
                    onClick={geolocateControlTrigger}
                >
                  Vis min posisjon
                </button>
              </div>
            </div>
          </div>
        </div>
      </ActivityProvider>
    </Layout>
  )
}

export const query = graphql`
  query ActivityMapPageQuery($id: String) {
    activity: sanityActivity(id: { eq: $id }) {
      ...ActivityFields
    }
    activities: allSanityActivity(
      sort: {
        fields: orderRank
      },
      filter: {
        slug: {
          current: {
            ne: null
          }
        }
        category: {
          slug: {
            current: {
              ne: null
            }
          }
        },
        location: {
          lat: {
            ne: null
          },
          lng: {
            ne: null
          }
        }
      }
    ) {
      edges {
        node {
          ...ActivityFields
        }
      }
    }
  }
`

export default MapPage
